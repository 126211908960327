<template lang="pug">
  .manager-firmwares-show
    b-row.mb-5
      b-col
        firmwares-panel(ref='firmwaresPanel')
    b-row.mb-5
      b-col
        router-view(@firmware-changed='reloadFirmwaresPanel')
</template>

<script>
import FirmwaresPanel from '@views/container/manager/firmwares/Panel'

export default {
  name: 'manager-firmwares-show',
  components: {
    FirmwaresPanel,
  },
  methods: {
    reloadFirmwaresPanel() {
      this.$refs.firmwaresPanel.panelReload()
    },
  },
}
</script>
